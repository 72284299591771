import {useGeneralApp} from "~/stores/generalApp.js";
import {useUserStore} from "~/stores/user.js";
import {UseUpdateData} from "~/composables/updateData.js";
import {UseGetData} from "~/composables/getData.js";
import {useNotifications} from "~/stores/notifications.js";
import {navigateTo} from "#app";
import {useUpdateUserLogin} from "~/composables/general/updateUserLogin.js";


export const useHandleLoginConfirm = async (code) => {
    const generalApp = useGeneralApp()
    const config = useRuntimeConfig();
    const notifications = useNotifications()
    try {
        const userStore = useUserStore()
        generalApp.requestProcessing = true
        await fetch(`${config.public.apiBase}/auth/confirm`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                "phone": generalApp.userPhone,
                "code": code
            })
        }).then(async (response) => {
            switch (response.status) {
                case 401: {
                    let res = await response.json();
                    switch (res.StatusText) {
                        case "AuthenticationFailed": {
                            notifications.wrongPinCode = true
                            generalApp.requestProcessing = false
                            generalApp.confirmPin = []
                            setTimeout(() => {
                                notifications.wrongPinCode = false
                            }, 3000)
                            break
                        }
                        case "AuthVerifyCodeExpired" : {
                            notifications.pinCodeOutdated = true
                            generalApp.requestProcessing = false
                            generalApp.confirmPin = []
                            setTimeout(() => {
                                notifications.pinCodeOutdated = false
                            }, 3000)
                            break
                        }
                    }
                    break
                }
                case 422: {
                    notifications.wrongConfirmData = true
                    generalApp.requestProcessing = false
                    generalApp.confirmPin = []
                    setTimeout(() => {
                        notifications.wrongConfirmData = false
                    }, 3000)
                    break
                }
                case 500: {
                    const generalApp = useGeneralApp()
                    const notifications = useNotifications();
                    notifications.networkError = true
                    generalApp.requestProcessing = false
                    const networkError = document.getElementById('network-error-dialog')
                    networkError.showModal();
                    break
                }
                case 200: {
                    let res = await response.json();
                    await UseUpdateData('app','isAuthorized', true).then(async () => {
                        generalApp.userPhone = ''
                        generalApp.confirmPin = []
                        generalApp.lastGetCodeRequested = 0
                        generalApp.retryGetCode = 1
                        await UseUpdateData('user', 'id', res.Data.user.id).then(async() => {
                            userStore.testUser.id = await UseGetData('user', 'id')
                        })
                        await useUpdateUserLogin(res)
                        await UseUpdateData('user', 'token', res.Data.authorization.token).then(async() => {
                            userStore.token = await UseGetData('user', 'token')
                        })
                        await UseUpdateData('user', 'role', res.Data.user.role).then(async() => {
                            userStore.testUser.role = await UseGetData('user', 'role')
                        })
                        generalApp.isAuthorized = await UseGetData('app','isAuthorized')
                    })
                    generalApp.requestProcessing = false
                    navigateTo('/')
                    return window.location.reload();
                }
                default: {
                    const notifications = useNotifications();
                    notifications.networkError = true
                    generalApp.requestProcessing = false
                    const networkError = document.getElementById('network-error-dialog')
                    networkError.showModal();
                }
            }
        })
    } catch (err) {

        console.log(err.data, 'error during login')
    }
}